import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';

import PhilosophyItem from '@/components/atoms/philosophy/PhilosophyItem';

const philosophyContentsWrapper = (theme: Theme) => css({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

const philosophyContentsBox = (theme: Theme) => css({
  width: '90%',
  [theme.breakpoints.down('md')]: {
    margin: '2rem auto',
  }
});

const PhilosophyContents: React.VFC = () => {
  return (
    <Box css={philosophyContentsWrapper}>
      <Box css={philosophyContentsBox}>
        <PhilosophyItem
          title="誠実"
          text="私利私欲を交えず、真心をもって人や物事にあたるべし"
        />
        <PhilosophyItem
          title="中正"
          text="かたよらず、公正であるべし"
        />
        <PhilosophyItem
          title="剛健"
          text="心身が強く、たくましくあるべし"
        />
      </Box>
    </Box>
  );
};

export default PhilosophyContents;
