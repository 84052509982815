import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type ValueItemProps = {
  title: string;
  titleEn: string;
  text: string;
}

const valueItemBox = (theme: Theme) => css({
  margin: '1.5rem auto',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    height: '7.2rem',
  }
});

const valueTitle = css({
  color: '#ffffff',
  fontSize: '1.5rem',
  fontWeight: 600
});

const valueTitleEn = (theme: Theme) => css({
  color: '#ffffff',
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    color: '#A09B9A',
    fontSize: '1.6rem',
    fontWeight: 600,
    bottom: 0,
    right: 48,
  }
});

const valueText = css({
  color: '#ffffff',
  margin: '.25rem auto',
});

const ValueItem: React.VFC<ValueItemProps> = (props) => {
  const { title, titleEn, text } = props;

  return (
    <Box css={valueItemBox}>
      <Typography css={valueTitle}>{ title }</Typography>
      <Typography css={valueTitleEn}>{ titleEn }</Typography>
      <Typography css={valueText}>{ text }</Typography>
    </Box>
  );
};

export default ValueItem;
