import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import InfoTypeDisplay from '@/components/molecules/common/InfoTypeDisplay';
import OathContents from '@/components/molecules/philosophy/OathContents';

const oathFillImageBox = css({
  height: 'calc(100% - 30vh)',
});

const oathFillImage = css({
  height: '100%',
  objectFit: 'cover',
});

const Oath: React.VFC = () => {
  return (
    <section>
      <Grid container>
        <Grid item xs={12} md={4}>
          <InfoTypeDisplay
            backgroundColor="primary"
            titleTextEn={['Oath']}
            titleText="安全行動の誓い"
            fixed
          />
          <Box
            css={oathFillImageBox}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block'
              }
            }}
          >
            <StaticImage
              alt="inPhilosophy02"
              src="../../../images/decoration/inPhilosophy02.jpg"
              css={oathFillImage}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <OathContents />
        </Grid>
      </Grid>
    </section>
  );
};

export default Oath;
