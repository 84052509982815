import React from 'react';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';

import InfoTypeDisplay from '@/components/molecules/common/InfoTypeDisplay';
import PhilosophyContents from '@/components/molecules/philosophy/PhilosophyContents';

const Philosophy: React.VFC = () => {
  return (
    <section>
      <Grid container>
        <Grid item xs={12} md={4}>
          <InfoTypeDisplay
            backgroundColor="primary"
            titleTextEn={['Philosophy']}
            titleText="社是"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <PhilosophyContents />
        </Grid>
      </Grid>
    </section>
  );
};

export default Philosophy;
