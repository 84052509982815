import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import InfoTypeDisplay from '@/components/molecules/common/InfoTypeDisplay';
import ValueContents from '@/components/molecules/philosophy/ValueContents';

const valueFillImageBox = css({
  height: 'calc(100% - 30vh)',
});

const valueFillImage = css({
  height: '100%',
  objectFit: 'cover',
});

const Value: React.VFC = () => {
  return (
    <section>
      <Grid container direction="row-reverse">
        <Grid item xs={12} md={4}>
          <InfoTypeDisplay
            backgroundColor="secondary"
            titleTextEn={['Value']}
            titleText="行動指針"
            fixed
          />
          <Box
            css={valueFillImageBox}
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block'
              }
            }}
          >
            <StaticImage
              alt="inPhilosophy01"
              src="../../../images/decoration/inPhilosophy01.jpg"
              css={valueFillImage}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <ValueContents />
        </Grid>
      </Grid>
    </section>
  );
};

export default Value;
