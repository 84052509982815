import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';

import ValueItem from '@/components/atoms/philosophy/ValueItem';
import Constitution from '@/components/atoms/philosophy/Constitution';

const valueContentsWrapper = (theme: Theme) => css({
  backgroundColor: theme.palette.backgroundDark.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const valueContentsBox = (theme: Theme) => css({
  margin: '3rem auto',
  width: '90%'
});

const ValueContents: React.VFC = () => {
  return (
    <Box css={valueContentsWrapper}>
      <Box css={valueContentsBox}>
        <ValueItem
          title="1. 安全第一"
          titleEn="safety first"
          text="安全第一こそ経営の要、法令を厳守し実行します。"
        />
        <ValueItem
          title="2. お客様視点"
          titleEn="customer orientation"
          text="常にお客様の視点に立ち、安心と満足のために行動します。"
        />
        <ValueItem
          title="3. 社会への責任"
          titleEn="social responsibility"
          text="私たちは、日本の建設業としての誇りを持ち、社会に貢献します。"
        />
        <ValueItem
          title="4. チームスピリット"
          titleEn="team spirit"
          text="個性と意見を尊重しあい、知識とアイデアを共有し、常に議論し一致して行動します。"
        />
        <ValueItem
          title="5. 努力と挑戦"
          titleEn="endeavor"
          text="常に努力し、目標を達成するまで挑戦し続けます。"
        />
        <ValueItem
          title="6. 環境への責任"
          titleEn="environmental responsibility"
          text="地球の自然環境を次世代へ引き継ぐため、より良い選択しを提示し実践します。"
        />
        <Constitution
          date="2020年4月1日 改定"
          ceoName="芳賀真人"
          dark
        />
      </Box>
    </Box>
  );
};

export default ValueContents;
