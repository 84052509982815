import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';

import Layout from '../layouts/Layout';
import FirstView from '@/components/organisms/common/FirstView';
import Philosophy from '@/components/organisms/philosophy/Philosophy';
import Value from '@/components/organisms/philosophy/Value';
import Oath from '@/components/organisms/philosophy/Oath';
import ContactLinkBlock from '@/components/organisms/common/ContactLinkBlock';
import BottomMenu from '@/components/organisms/common/BottomMenu';
import Seo from '@/components/organisms/common/Seo';

const FirstViewImage = (theme: Theme) => css({
  height: '25vh',
  [theme.breakpoints.down('xs')]: {
    height: '20vh',
  }
});

const PhilosophyPage: React.VFC<PageProps> = () => {
  return (
    <Layout>
      <Seo
        title="私たちの哲学"
        description="株式会社 富士見工務店では、社是、行動指針、安全行動の誓いを定め、お客様によりよいサービスを提供します。"
      />
      <FirstView
        titleEn="Philosophy"
        title="私たちの哲学"
        titlePosition="right"
      >
        <StaticImage
          alt="PhilosophyImage"
          src="../images/firstviews/philosophy.jpg"
          css={FirstViewImage}
          layout="fullWidth"
          objectPosition="50% 50%"
        />
      </FirstView>
      <Philosophy />
      <Value />
      <Oath />
      <ContactLinkBlock />
      <BottomMenu />
    </Layout>
  );
};

export default PhilosophyPage;
