import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type PhilosophyItemProps = {
  title: string;
  text: string;
}

const philosophyItemBox = css({
  margin: '1rem auto',
});

const philosophyTitle = css({
  fontSize: '1.5rem',
  fontWeight: 600
});

const philosophyText = css({
  margin: '.5rem auto',
});

const PhilosophyItem: React.VFC<PhilosophyItemProps> = (props) => {
  const { title, text } = props;

  return (
    <Box css={philosophyItemBox}>
      <Typography css={philosophyTitle}>{ title }</Typography>
      <Typography css={philosophyText}>{ text }</Typography>
    </Box>
  );
};

export default PhilosophyItem;
