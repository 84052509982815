import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type ConstitutionProps = {
  date: string;
  ceoName: string;
  dark?: boolean;
};

const constitutionBox = (theme: Theme) => css({
  width: '50%',
  margin: '1rem 0 1rem auto',
  [theme.breakpoints.down('lg')]: {
    width: '72%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: '1rem auto'
  }
});

const darkColor = css({
  color: '#ffffff'
});

const Constitution: React.VFC<ConstitutionProps> = (props) => {
  const { date, ceoName, dark } = props;

  return (
    <Box css={constitutionBox}>
      <Typography css={dark && darkColor}>{date}</Typography>
      <Typography css={dark && darkColor}>
        <span>株式会社富士見工務店 代表取締役</span>
        <span>{ ceoName }</span>
      </Typography>
    </Box>
  );
};

export default Constitution;
