import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Constitution from '@/components/atoms/philosophy/Constitution';

const oathContentsWrapper = (theme: Theme) => css({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%'
});

const oathContentsBox = css({
  width: '90%',
  margin: '2rem auto'
});

const oathContentIntroBox = css({
  margin: '1rem auto',
});

const oathItems = css({
  margin: '2rem auto'
});

const oathItemStyles = (theme: Theme) =>  css({
  fontSize: '1.5rem',
  fontWeight: 600,
  margin: '2rem 0',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.3rem',
  }
});

const OathContents: React.VFC = () => {
  return (
    <Box css={oathContentsWrapper}>
      <Box css={oathContentsBox}>
        <Box css={oathContentIntroBox}>
          <Typography>
            人命の尊重は、我ら絶対の使命であり願いである
            <br />
            悲惨な事故に人の身、わが身、家族らが不幸にならぬよう全力を挙げて無事故の達成にまい進することを誓う
          </Typography>
        </Box>
        <Box css={oathItems}>
          <Typography css={oathItemStyles}>
            <span>一、</span>
            <span>安全のためにできることを常に考える。</span>
          </Typography>
          <Typography css={oathItemStyles}>
            <span>一、</span>
            <span>ルールや手順の検討を怠らない。</span>
          </Typography>
          <Typography css={oathItemStyles}>
            <span>一、</span>
            <span>仲間の危険を避けるよう、ためらわず行動する。</span>
          </Typography>
          <Typography css={oathItemStyles}>
            <span>一、</span>
            <span>予定と違う状況には、連絡、報告、相談する。</span>
          </Typography>
          <Typography css={oathItemStyles}>
            <span>一、</span>
            <span>危険だと思うときには作業中止する事も安全に対する勇気ある行動である。</span>
          </Typography>
          <Typography css={oathItemStyles}>
            <span>一、</span>
            <span>あなたの大切な人・家族のもとへ無事故で戻ることが最大の幸せである。</span>
          </Typography>
        </Box>
        <Constitution
          date="2019年9月18日 制定"
          ceoName="芳賀真人"
        />
      </Box>
    </Box>
  );
};

export default OathContents;
